<template>
  <header>
    <div class="wrapper">
      <div class="header">
        <h1 class="logo">Roses Republic</h1>
        <nav>
          <ul class="navigation-list">
            <li class="navigation-list__item">
              <router-link to="/">Home</router-link>
            </li>
            <li class="navigation-list__item">
              <router-link to="/shop">Shop</router-link>
            </li>
            <li class="navigation-list__item">
              <router-link to="/contact-us">Contact us</router-link>
            </li>
          </ul>
        </nav>
        <div class="phones pointer" @click="transitionToSignIn">
          <img src="@/assets/icons/user.png" alt="user" />
          <p>Log in</p>
        </div>

        <div class="burger" @click="isOpenMob = !isOpenMob">
          <img
            src="@/assets/icons/menu-close.png"
            alt="menu-close"
            v-if="isOpenMob"
          />
          <img src="@/assets/icons/menu.png" alt="menu" v-else />
        </div>
      </div>
      <transition name="menuAnimation">
        <div class="menu__inner-menu" v-if="isOpenMob">
          <ul>
            <li @click="isOpenMob = false">
              <router-link to="/">Home</router-link>
            </li>
            <li @click="isOpenMob = false">
              <router-link to="/shop">Shop</router-link>
            </li>
            <li @click="isOpenMob = false">
              <router-link to="/contact-us">Contact us</router-link>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isOpenMob: false,
    };
  },
  components:{
  },
  methods:{
    transitionToSignIn(){
      this.$router.push('/signIn')
    }
  },
  watch: {
    $route() {
      this.isOpenMob = false;
    },
    isOpenMob() {
      if (this.isOpenMob) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    },
  },
};
</script>
